/*
 * This file is Free Software under GNU Affero General Public License v >= 3.0
 * without warranty, see README.md and license for details.
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * SPDX-FileCopyrightText: 2020 nic.at GmbH <https://nic.at>
 * Software-Engineering: 2020 Intevation GmbH <https://intevation.de>
 *
 * Author(s):
 * * Fadi Abbud <fadi.abbud@intevation.de>
 */
import { HTTP } from "../lib/http";
import { getFlatView } from "../lib/use-mapper";

export const user = {
  namespaced: true,
  state: () => ({
    currentUser: {},
    userNodes: [],
    selectedNode: {},
    users: []
  }),
  mutations: {
    setCurrentUser: (state, data) => {
      state.currentUser = data;
    },
    setUserNodes: (state, nodes) => {
      state.userNodes = nodes;
    },
    setSelectedNode: (state, selected) => {
      state.selectedNode = selected;
    },
    setUsers: (state, users) => {
      state.users = users;
    }
  },
  actions: {
    loadUserConfig({ commit }) {
      const storedNode = JSON.parse(
        localStorage.getItem("selectedNode") || "{}"
      );
      HTTP.get("/config").then(response => {
        const { username, roles, fullname, groups } = response.data;
        commit("application/setContactRoles", response.data.contact_roles, {
          root: true
        });
        commit(
          "application/setContactRoleMapping",
          response.data.contact_role_mapping,
          {
            root: true
          }
        );
        commit("application/setNetobjectRules", response.data.netobject_rules, {
          root: true
        });
        if (groups.length !== 0) {
          if (groups.length > 1) {
            commit("setUserNodes", groups);
            // The localStorage is not yet set by the first login
            if (!Object.entries(storedNode).length) {
              // Select directly the active node without popping up "selectNode" dialog
              // in case there is only one active "node" while the other "nodes" are not set
              let activeNodes: object[] = [];
              groups.forEach(g => {
                if (g.level === "portaladmin") {
                  activeNodes.push({ level: "portaladmin" });
                }
                if (g.nodes && g.nodes.length) {
                  activeNodes = activeNodes.concat(
                    g.nodes.map(obj => ({ ...obj, level: g.level }))
                  );
                }
              });
              if (activeNodes.length === 1) {
                commit("setSelectedNode", activeNodes[0]);
              } else {
                commit("application/setShowSelectNodeDialog", true, {
                  root: true
                });
              }
            } else {
              // Set the selectedNode from the localStorage
              commit("setSelectedNode", storedNode);
            }
          } else {
            if (groups[0].nodes && groups[0].nodes.length) {
              if (groups[0].nodes.length > 1) {
                commit("setUserNodes", [groups[0]]);
                if (!Object.entries(storedNode).length) {
                  commit("application/setShowSelectNodeDialog", true, {
                    root: true
                  });
                } else {
                  commit("setSelectedNode", storedNode);
                }
              } else {
                commit("setSelectedNode", {
                  level: groups[0].level,
                  name: groups[0].nodes[0].name,
                  node_id: groups[0].nodes[0].node_id
                });
              }
            } else {
              // portaladmin
              if (groups[0].level === "portaladmin") {
                commit("setSelectedNode", { level: groups[0].level });
              } else {
                // Nodes are missing
                commit("application/setShowSelectNodeDialog", true, {
                  root: true
                });
              }
            }
          }
        } else {
          commit("application/setShowSelectNodeDialog", true, {
            root: true
          });
        }
        commit("setCurrentUser", {
          username: fullname ? fullname : username,
          roles: roles,
          groups: groups
        });
      });
    },
    loadUsers({ commit, getters, state }, options) {
      return new Promise((resolve, reject) => {
        let route = "/users";
        if (getters.isOrgaAdmin) {
          route += "&ancestor=" + state.selectedNode.node_id;
        }
        if (getters.isTenantAdmin) {
          route += "&tenant=" + state.selectedNode.node_id;
        }
        if (options) {
          route += `&page=${options.page || 1}&row=${options.itemsPerPage ||
            10}${options.search ? "&username=" + options.search : ""}`;
        }

        if (options.filters) {
          options.filters.forEach(filter => {
            route += `&${filter.key}=${filter.value}`;
          });
        }

        if (options.portalRoleToFilterBy) {
          route += `&portal_role=${options.portalRoleToFilterBy}`;
        }

        route +=
          "&sort_direction=" +
          (options && options.sortForRequest ? options.sortForRequest : "asc");
        route = route.replace("&", "?");
        HTTP.get(route)
          .then(response => {
            commit("setUsers", response.data.data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  getters: {
    isOrgaAdmin: state => {
      return state.selectedNode.level === "orgaadmin";
    },
    isTenantAdmin: state => {
      return state.selectedNode.level === "tenantadmin";
    },
    isPortalAdmin: state => {
      return state.selectedNode.level === "portaladmin";
    },
    getUserTenants: (state, getters, rootState) => {
      if (getters["isOrgaAdmin"]) {
        return [];
      }
      if (getters["isPortalAdmin"]) {
        return rootState.application.tenants;
      }
      if (getters["isTenantAdmin"]) {
        if (!getters["getNodes"]) {
          return [];
        } else {
          const tenantIds = getters["getNodes"].map(n => {
            if (n.level === "tenantadmin") return n.node_id;
          });
          return rootState.application.tenants.filter(t =>
            tenantIds.some(id => id === t.tenant_id)
          );
        }
      }
    },
    getSelectedNode: state => {
      return state.selectedNode;
    },
    getNodes: state => {
      const tmp: object[] = [];
      // User has many groups
      if (state.userNodes.length > 1) {
        state.userNodes.forEach(g => {
          if (g.nodes && g.nodes.length) {
            // many nodes in this group
            if (g.nodes.length > 1) {
              g.nodes.forEach(n => {
                tmp.push({
                  level: g.level,
                  name: n.name,
                  node_id: n.node_id,
                  parents: getFlatView(n.parents ? n.parents : []),
                  tenants: n.tenants
                });
              });
            } else {
              tmp.push({
                name: g.nodes[0].name,
                node_id: g.nodes[0].node_id,
                level: g.level,
                parents: getFlatView(
                  g.nodes[0].parents ? g.nodes[0].parents : []
                ),
                tenants: g.nodes[0].tenants
              });
            }
          } else {
            tmp.push({ level: g.level });
          }
        });
      }
      if (state.userNodes.length === 1) {
        state.userNodes[0].nodes.forEach(n => {
          tmp.push({
            level: state.userNodes[0].level,
            name: n.name,
            node_id: n.node_id,
            parents: getFlatView(n.parents ? n.parents : []),
            tenants: n.tenants
          });
        });
      }
      return tmp;
    }
  }
};
